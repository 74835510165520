<template>
    <vue-draggable-resizable :h="400" :w="300">
        <v-toolbar dense>
            <v-toolbar-title>{{ title }}</v-toolbar-title>
            <v-spacer />
            <v-btn
                v-if="canClose"
                @click="close"
                icon
                small
                title="Close">
                <v-icon small>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <!-- style="opacity: 0.97" -->
        <v-card
            height="100%"
            width="100%"
            class="overflow-y-auto">
            <v-text-field
                flat
                dense
                hide-details
                hide-no-data
                solo-inverted
                small
                single-line
                append-icon="mdi-close"
                label="Find"
                v-model="searchInput"
                @click:append="searchInput = null" />
            <drop @drop="insertItem" style="height: 100%">
                <v-card-text class="pa-0" full-width>
                    <v-list dense>
                        <v-list-item-group>
                            <template v-for="item in filteredItems">
                                <drag
                                    :key="item.id"
                                    :data="item">
                                    <v-list-item dense>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ getNestedValue(item, itemText) }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </drag>
                            </template>
                        </v-list-item-group>
                    </v-list>
                </v-card-text>
            </drop>
            <v-overlay :value="error != null" absolute class="text-center">
                <v-progress-circular indeterminate size="64" />
                <p>{{ error }}</p>
            </v-overlay>
        </v-card>
        <v-overlay :value="isLoading" absolute class="text-center">
            <v-progress-circular indeterminate size="64" />
            <p>Loading</p>
        </v-overlay>
    </vue-draggable-resizable>
</template>

<script>
import { firstBy } from 'thenby';
import { Drag, Drop } from "vue-easy-dnd";

export default {
    name: 'BT-Board-Supply-Box',
    components: {
        VueDraggableResizable: () => import('vue-draggable-resizable'),
        Drag,
        Drop
    },
    data: function() {
        return {
            asyncItems: [],
            error: null,
            isFiltering: true,
            isLoading: false,
            msg: null,
            searchInput: null
        }
    },
    async mounted() {
        await this.pullItems();
    },
    props: {
        canClose: {
            type: Boolean,
            default: true
        },
        canInsert: {
            type: Boolean,
            default: true
        },
        item: null,
        idProp: null,
        itemText: null,
        navigation: null,
        onFilter: {
            type: Function,
            default: null
        },
        sortProp: null,
        title: null
    },
    computed: {
        filteredItems() {
            console.log('fItems');
            var r = this.asyncItems;
            if (this.isFiltering) {
                if (this.onFilter != null) {
                    r = this.onFilter(r, this.idProp, this.item);
                }
                else {
                    r = r.filter(x => x[this.idProp] == null);
                }
            }
            
            if (this.sortProp != null) {
                if (this.searchInput != null) {
                    r = r.filter(x => this.hasSearch(x, this.searchInput, [this.sortProp]));
                }

                r.sort(firstBy(x => this.getNestedValue(x, this.sortProp)));
            }

            return r;
        },
    },
    methods: {
        close() {
            this.$emit('close', {
                navigation: this.navigation
            });
        },
        copyItem() {
            console.log('copying item');
            return true;
        },
        async insertItem(d) {
            if (!this.canInsert) {
                return;
            }
            
            try {
                this.isLoading = true;

                d.data[this.idProp] = null;

                var res = await this.$BlitzIt.store.patch(this.navigation, d.data);

                d.data.rowVersion = res.rowVersion;
            }
            catch (err) {
                this.error = this.extractErrorDescription(err);
            }
            finally {
                this.isLoading = false;
            }
        },
        async pullItems() {
            try {
                this.isLoading = true;
                this.asyncItems = await this.$BlitzIt.store.getAll(this.navigation, null, false, null, null);
            }
            catch (err) {
                this.error = this.extractErrorDescription(err);
            }
            finally {
                this.isLoading = false;
            }
        },
    }
}
</script>